// @ts-check

// @ts-expect-error Don't know how to fix this using JSDoc
import ModelContainer from "@core/scripts/components/model-container";
// @ts-expect-error Don't know how to fix this using JSDoc
import JshModule from '@core/scripts/helper/jsh-module';
import * as contentful from 'contentful';
import { createRoot } from 'react-dom/client';
import { ServicePageBreadcrumbs } from '../components/ServicePageBreadcrumbs.jsx';

const { moduleScope } = JshModule('service/service-page-breadcrumbs');
const model = ModelContainer.get("servicePageModel");

const client = contentful.createClient({
  accessToken: "not_used",
  space:  "not_used"
});

const cdaEntry = client.parseEntries(JSON.parse(model.cdaEntryNavigation)).items[0];

if (cdaEntry) {
  createRoot(moduleScope)
    // @ts-expect-error
    .render(<ServicePageBreadcrumbs cdaEntry={ cdaEntry } />);
} else {
  console.warn("[Service Page Breadcrumbs] No Contentful entry found");
}
